<template>
    <el-upload
        :http-request="fnUploadRequest"
        :show-file-list="false"
        :on-exceed="beyondFile"
        :on-success="handleVideoSuccess"
        :before-upload="beforeUploadVideo">
        <el-button type="primary" plain v-loading="loadingOne">{{btnTips}}封面图上传</el-button>
    </el-upload>
</template>

<script>
const code = 200
const Util = require('@/assets/utils');
  export default {
    props:{
        btnTitle: String
    },
    computed:{
        btnTips:{
            get: function(){
                return this.btnTitle
            }
        }
    },
    data() {
      return {
          loadingOne: false
        };
    },
    methods: {
      async fnUploadRequest (option) {
            this.loadingOne = true
            let list = '/cover/'
            const filePath = await Util.upload(list, option.file)
            // this.$emit('input', filePath)
            this.$emit("success",filePath)
            this.loadingOne = false
            // console.log('success ~ ', filePath)
        },
        // 视频上传
        beforeUploadVideo (file) {
            //todo
            const isJPG = file.type === 'image/jpeg' || 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 4;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        // 视频上传成功后
        handleVideoSuccess (response, file, fileList) {
            //todo
        },
        // 视频添加多个视频文件事件
        beyondFile (files, fileList) {
            //todo
        },

        onError(error){
            console.log('error === ', error)
        },
        
        handleAvatarSuccess(res, file) {
            if(res.code == code){
                this.imageUrl = res.path;
                // input 父组件可以使用v-model动态绑定
                console.log('1233234234')
                this.$emit('input', res.path)
            }
            console.log(res)
            // this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 4;
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        }
    }
  }
</script>

<style>

</style>
